export enum Actions {
    // action types
    SAVE_NEW_SALE = "SAVE_NEW_SALE"
    , RESET_NEW_SALE = "RESET_NEW_SALE"
    , ACTIVE_SALE = "ACTIVE_SALE"
    , DISABLE_SALE = "DISABLE_SALE"
    , UPDATE_SALE = "UPDATE_SALE"
    , SEARCH_INFO_SALE = "SEARCH_INFO_SALE"
    , ADD_PRODUCT = "ADD_SALE"
    , DELETE_SAVE_PRODUCT = "DELETE_SAVE_PRODUCT"
    , UPDATE_PRODUCT = "UPDATE_PRODUCT"
    , SAVE_PRODUCT = "SAVE_PRODUCT"
    , RESET_PRODUCT = "RESET_PRODUCT"
    , SEARCH_CLIENTS = "SEARCH_CLIENTS"
    , SEN_TO_PAY = "SEN_TO_PAY"
    , SEN_TO_SALE = "SEN_TO_SALE"
    , SEN_TO_SCHEDULE = "SEN_TO_SCHEDULE"
    , DISABLE_SALE_COTIZACION = "DISABLE_SALE_COTIZACION"
    , GET_QUOTEVIE = "GET_QUOTEVIE"
    , GET_QUOTEVIE_COMPLETE = "GET_QUOTEVIE_COMPLETE"
    , GET_RECEIP_VIEW= "GET_RECEIP_VIEW"
}

export default Actions;