
export enum Mutations {
    SET_VALUE_LOADING = "SET_VALUE_LOADING"
    , SET_LOADINGS = "SET_LOADINGS"
    , SET_DATA_SALE = "SET_DATA_CLIENT"
    , RESET_VALUES_FORM = "RESET_VALUES_FORM"
    , SET_INITIAL_DATA_SALE = "SET_INITIAL_DATA_SALE"
    , RESET_ID_SALE = "RESET_ID_SELECT"
    , ADD_PRODUCT = "ADD_PRODUCT"
    , DELETE_PRODUCT = "DELETE_PRODUCT"
    , UPDATE_EDIT_PRODUCT = "UPDATE_EDIT_PRODUCT"
    , SAVE_EDIT_PRODUCT = "SAVE_EDIT_PRODUCT"
    , RESET_EDIT_PRODUCT = "RESET_EDIT_PRODUCT"
    , SET_CLIENTS = "SET_CLIENTS"
    , SET_LOADING_CLIENTS = "SET_LOADING_CLIENTS"
    , SAVE_CLIENT_SELECTED = "SAVE_CLIENT_SELECTED"
    , SAVE_TAX_VALUE = "SAVE_TAX_VALUE"
    , SET_QUOTEVIE = "SET_QUOTEVIE"
    , SET_QUOTEVIE_COMPLETE = "SET_QUOTEVIE_COMPLETE"
}
